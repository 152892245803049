import IBankinEnvConfig from "./IBankinEnvConfig";
import LocalConfig from "factor-lib/envs/LocalConfig.ts";

const LocalEnv: IBankinEnvConfig = (
    {
        ...LocalConfig,

        // ...BaseLocalConfig,
        backendUrl: 'http://localhost:7071',
    }
);

export default LocalEnv;