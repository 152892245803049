import {useParams} from "react-router-dom";
import {storeCustomerToken} from "factor-lib/customerBankin/customerTokenStorageUtils.ts";
import AuthTokenContext from "../../reactcontext/AuthTokenContext.tsx";
import App from "./App";
import customerTokenName from "../../customerTokenName.ts";

const BankinAppWithCustomerTokenWrapper = () => {
    const params = useParams();
    const customerTokenValue = params[customerTokenName]!
    storeCustomerToken(customerTokenValue);
    return (
        <AuthTokenContext.Provider value={customerTokenValue}>
            <App />
        </AuthTokenContext.Provider>
    );
}

export default BankinAppWithCustomerTokenWrapper;