import IIntercom, {IIntercomUser} from './IIntercom';

// eslint-disable no-console

export default class IntercomLogging implements IIntercom {

    public inited = false;
    public userSet = false;
    public actionSet: string | null = null;

    public init(userInfos: IIntercomUser | null) {
        this.log(`init for ${!!userInfos ? userInfos.email : '<<anonymous user>>'}`);
        this.inited = true;
    }

    public setUser(userInfos: IIntercomUser | null) {
        this.log(`set user ${userInfos?.email}`);
        this.userSet = true;
    }

    public action(action: string) {
        this.log(`action: ${action}`);
        this.actionSet = action;
    }

    protected log(message: string) {
        console.log(`[Intercom] ${message}`);
    }
}
