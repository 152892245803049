import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {DefaultAxiosTimeoutMilliseconds} from "../serverUtils/axiosConfigUtils.ts";
import {DefaultGenericUnexpectedErrorMessage, LongGenericUnexpectedErrorMessage} from "../errorsUtils.ts";
import {AccountInfo} from "@azure/msal-common";
import HandledError from "../Errors/HandledError.ts";
import {getLibFactorContext} from "../contexts/ILibFactorContext.ts";
import {getOAuth2Context} from "../contexts/IOauth2Context.ts";
import DoNotRetryHandledError from "../Errors/DoNotRetryHandledError.ts";
import ILoggerLevel from "../services/logger/ILoggerLevel.ts";

export const RequestWoResponseMessage = 'Error for server call without response';

export const ServerDownMessage = 'Temporairement indisponible'; // handle timeout, ...

const formatArray = (a: string[]): string => a.length === 1 ? a[0] : `[${a.join(',')}]`;

const processWithResponseData = (
    // showUnexpectedError: boolean,
    formatLongResponse: boolean,
    responseStatus: number,
    responseContentType: string | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: any
): string => {
    if (formatLongResponse && (response === DefaultGenericUnexpectedErrorMessage)) {
        return LongGenericUnexpectedErrorMessage;
    } // else leave default
    // const { logger } = getLibFactorContext();
    // logger.trackTrace(ILoggerLevel.Information, responseContentType!);
    if (responseStatus === 400 && responseContentType === "application/problem+json; charset=utf-8") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const responseTitle = response.title as string;
        if (responseTitle === "One or more validation errors occurred.") {
            // Pas besoin de showUnexpectedError : le backend doit deja le gerer
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return `Erreur(s): ${formatArray(Object.entries((response.errors as Record<string, string[]>)).map((r) => `${r[0]} : ${formatArray(r[1])}`))}`;
        }
        return responseTitle; // TODO : don't we want to hide for the user ?
    }
    if (responseStatus === 415) {
        if (responseContentType === "application/json; charset=utf-8") {
            // Pas besoin de showUnexpectedError : le backend doit deja le gerer
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return response.title as string;  // TODO : don't we want to hide for the user ? Le backend le gere-t-il deja ?
        }
        if (responseContentType === "application/problem+json; charset=utf-8") {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return response.title as string;  // TODO : don't we want to hide for the user ? Le backend le gere-t-il deja ?
        }
    }
    return response as string;
}

export const defaultSpecificErrorHandlerAsync = <TO> (response: AxiosResponse): Promise<{ value: TO } | null> => {
    if (response.status === 501) {
        // On log en info cote serveur
        throw new DoNotRetryHandledError(response.data as string);
    }
    return Promise.resolve(null);
};

// const specificJsonContentType = new Set<string>(['application/problem+json; charset=utf-8', 'application/json', 'application/json; charset=utf-8']);

export const ContentTypeHeaderName = "content-type";

// https://axios-http.com/docs/handling_errors
// returns message for user
const checkAxiosResponseAsync = async <EO> (
    formatLongResponse: boolean, // reprendre, tout vérifier
    error: Error,
    customerResponseProcessor: (r: AxiosResponse) => Promise<{ value: EO } | null>
): Promise<EO> => {

    if (axios.isAxiosError(error)) {

        const responseO = error.response;

        const { logger, showUnexpectedError } = getLibFactorContext();

        if (!!responseO) {

            const response: AxiosResponse = responseO;

            const customResponse = await customerResponseProcessor(response);

            if (!!customResponse) {
                return customResponse.value;
            }

            // Then unexpected
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
            const responseData: any = response.data;

            // Maintenant, on le fait cote back
            // // Specific to our controllers
            // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            // const responseData = response.data;
            // if (response.status === 400) {
            //     const contentType: string = response.headers['content-type'];
            //
            //     if (specificJsonContentType.has(contentType)) {
            //         return JSON.stringify(responseData.errors);
            //     }
            // }

            // Note : on devrait peut etre etre moins 'severe' avec les erreurs 5XX,
            // ce n'est pas le pb du front.
            // On les loggue en erreur pour l'instant, mais on pourrait changer plus tard.
            logger.trackTrace(ILoggerLevel.Error, `Unexpected error for server call response '${error.message}' - http status : ${response.status} - ${JSON.stringify(responseData) /* https://portal.azure.com/#blade/AppInsightsExtension/DetailsV2Blade/DataModel/%7B%22eventId%22:%22aea3160f-d09d-11ee-ba97-0022488241bf%22,%22timestamp%22:%222024-02-21T09:42:41.272Z%22%7D/ComponentId/%7B%22Name%22:%22factor-prod-admin%22,%22ResourceGroup%22:%22FACTOR-PROD%22,%22SubscriptionId%22:%229b6f2b95-cd0b-4a8a-a702-931467acfd3e%22%7D */}`, {
                httpStatusCode: response.status.toString(10),
                message: error.message,
                code: error.code
            });

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const responseContentType = (response.headers[ContentTypeHeaderName]) as string | undefined;

            // Must not be empty : will be displayed to the user
            const userResponseMessage = !!responseData && (!(responseData instanceof String) || (responseData as string).length > 0)
                ? processWithResponseData(
                        // showUnexpectedError,
                        formatLongResponse,
                        response.status,
                        responseContentType,
                        responseData
                )
                : showUnexpectedError
                    ? `Erreur http code ${response.status}` // response.statusText is not present in Azure
                    : DefaultGenericUnexpectedErrorMessage;

            if (response.status >= 500 && response.status < 600) {
                // Unexpected : we can try again
                // Case 501 must have been handled before, as it is expected
                throw new HandledError(userResponseMessage);
            } // else

            throw new DoNotRetryHandledError(userResponseMessage);
        } // else

        if (error.request) {

            if (axios.isCancel(error)) {
                logger.trackTrace(ILoggerLevel.Error, 'Request cancelled');
                throw new HandledError(ServerDownMessage);  // Un peu trompeur ...
            } else {
                logger.trackTrace(ILoggerLevel.Error, RequestWoResponseMessage);
                throw new HandledError(ServerDownMessage);
            }
        }
    }


    /* eslint-enable */

    // Else generic errror
    throw error;
    /*
        extractErrorMessage(
            errorParams,
            formatLongResponse,
            error
        ); //*/
}

export const getTimeout = (timeout?: number /* TODO : enum ? */): number =>
    timeout ?? DefaultAxiosTimeoutMilliseconds;
    // timeout === undefined /* No '??' : can be 0 */ ? DefaultAxiosTimeoutMilliseconds : timeout

export const axiosConfigOauth2Async = async (
    scope: string,
    account: AccountInfo,
    signal?: AbortSignal, // more frequent, only for queries, not for mutations
    timeout?: number /* TODO : enum ? */
): Promise<AxiosRequestConfig> =>
    ({
        signal,
        timeout: getTimeout(timeout),
        headers: {
            Authorization: `Bearer ${await getOAuth2Context().getTokenAsync(account, [scope/* , 'profile' *//* , 'openid' */])}`
        }
    });

/* eslint-disable @typescript-eslint/no-explicit-any */

export const axiosGet = async <R>(
    scope: string,
    url: string,
    account: AccountInfo,
    signal?: AbortSignal,
    timeout?: number
): Promise<R> =>
    await axiosGet2<R, R>(
        url,
        await axiosConfigOauth2Async(scope, account, signal, timeout),
        p => p,
        defaultSpecificErrorHandlerAsync
    );

export const axiosGet2 = <R = void, TO = R>(
    url: string,
    config: AxiosRequestConfig,
    onSuccess: (v: R) => TO,
    customerResponseProcessor: (r: AxiosResponse) => Promise<{ value: TO } | null> = defaultSpecificErrorHandlerAsync,
    formatLongResponse = false
): Promise<TO> =>
    getLibFactorContext().axios.get<R, AxiosResponse<R>>(
        url,
        config
    )
        .then((r) => onSuccess(r.data))
        .catch((e: Error) =>
            checkAxiosResponseAsync<TO>(
                formatLongResponse, // false, // TODO : a parameter
                e,
                customerResponseProcessor
            )
        );

export const ContentTypeApplicationJson = 'application/json';

export const withContentTypeO = (
    baseConfig: AxiosRequestConfig,
    contentType?: string
): AxiosRequestConfig => {
    if (!!contentType) {
        return ({
            ...baseConfig,
            headers: {
                ...baseConfig.headers,
                "Content-Type": contentType
            }
        });
    } // else
    // Nothing to enrich
    return baseConfig;
}

export const axiosPost2 = <R = void, I = any, TO = R>(
    url: string,
    data: I,
    config: AxiosRequestConfig,
    onSuccess: (v: R) => TO,
    customerResponseProcessor: (r: AxiosResponse) => Promise<{ value: TO } | null> = defaultSpecificErrorHandlerAsync,
    formatLongResponse = false
): Promise<TO> =>
    getLibFactorContext().axios.post<R, AxiosResponse<R>, I>(
        url,
        data,
        config
    )
        .then((r) => onSuccess(r.data))
        .catch((e: Error) => checkAxiosResponseAsync<TO>(
            formatLongResponse,
            e,
            customerResponseProcessor
        ));

export const axiosPut2 = <R = void, I = any, TO = R>(
    url: string,
    data: I,
    config: AxiosRequestConfig,
    onSuccess: (v: R) => TO,
    customerResponseProcessor: (r: AxiosResponse) => Promise<{ value: TO } | null> = defaultSpecificErrorHandlerAsync,
    formatLongResponse = false
): Promise<TO> =>
    getLibFactorContext().axios.put<R, AxiosResponse<R>, I>(
        url,
        data,
        config
    )
        .then((r) => onSuccess(r.data))
        .catch((e: Error) => checkAxiosResponseAsync<TO>(
            formatLongResponse,
            e,
            customerResponseProcessor
        ));

export const axiosDelete2 = <R = void, TO = R>(
    url: string,
    config: AxiosRequestConfig,
    onSuccess: (v: R) => TO,
    customerResponseProcessor: (r: AxiosResponse) => Promise<{ value: TO } | null> = defaultSpecificErrorHandlerAsync,
    formatLongResponse = false
): Promise<TO> =>
    getLibFactorContext().axios.delete<R, AxiosResponse<R>>(
        url,
        config
    )
        .then((r) => onSuccess(r.data))
        .catch((e: Error) => checkAxiosResponseAsync<TO>(
            formatLongResponse,
            e,
            customerResponseProcessor
        ));

/* eslint-enable */