import prodConfigFactory, {
    ProdTelemetryCustomerInstrumentationKey
} from "factor-lib/envs/prodConfigFactory";
import IBankinEnvConfig from "./IBankinEnvConfig";

const ProdEnv: IBankinEnvConfig = ({
    ...prodConfigFactory(
        ProdTelemetryCustomerInstrumentationKey,
        // 'https://salmon-grass-0a8343f03.1.azurestaticapps.net'
    ),
    backendUrl: 'https://api.bankin.dimpl.io', // 'https://factor-prod-bankin-function.azurewebsites.net'
});

export default ProdEnv;
