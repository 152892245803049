import ILogger from "./ILogger";
import {ICustomProperties} from "@microsoft/applicationinsights-core-js";
import ILoggerLevel from "./ILoggerLevel.ts";

export default class CompositeLogger implements ILogger {

    private _subLoggers: ILogger[];


    constructor(subLoggers: ILogger[]) {
        this._subLoggers = subLoggers;
    }

    public clearAuthenticatedUserContext(): void {
        this._subLoggers.forEach((l) => l.clearAuthenticatedUserContext());
    }

    public setAuthenticatedUserContext(userId: string, customerId: string | null, storeInCookie: boolean): void {
        this._subLoggers.forEach((l) => l.setAuthenticatedUserContext(userId, customerId, storeInCookie));
    }

    public trackException(exception: Error, customProperties: ICustomProperties | undefined): void {
        this._subLoggers.forEach((l) => l.trackException(exception, customProperties));
    }

    public trackTrace(severityLevel: ILoggerLevel, message: string, customProperties: ICustomProperties | undefined): void {
        this._subLoggers.forEach((l) => l.trackTrace(severityLevel, message, customProperties));
    }
}