import CloseButton from "./CloseButton";
import {CSSProperties, ReactNode} from "react";

import './modal.scss';

export const SectionId = 'modalSectionId';

const Modal = (
    {
        id,
        className,
        active,
        maxWidth,
        fullMaxWidth,
        close,
        children
    }: {
        id?: string;
        className?: string;
        active?: boolean; // default : true
        maxWidth?: string | null;
        fullMaxWidth: boolean; // TODO : default
        close?: () => void;
        children: ReactNode;
    }
) => {
    const modalStyle: CSSProperties = {};
    if (!!maxWidth) {
        modalStyle.maxWidth = maxWidth;
    }
    if (fullMaxWidth) {
        modalStyle.width = 'auto';
    }

    return (
        <div id={id}
             className={`modal${(active === undefined || active) ? ' is-active' : ''}${!!className ? ` ${className}` : ''}`}>
            <div className='modal-background'
                 onClick={() => {
                    if (!!close) {
                        close();
                    }
                 }} />
            <div className='modal-card' style={modalStyle}>
                { !!close &&
                    <CloseButton className='p-close'
                                 style={{zIndex: 8}}
                                 clickHandler={close} />
                }
                <section id={SectionId} className='modal-card-body'>
                    { children /* this.$slots.default  */}
                </section>
            </div>
        </div>
    );
};

export default Modal;
