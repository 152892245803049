import Button, {ButtonKind, ButtonSize} from "../Buttons/Button.tsx";
import {SynchronizeBankinId} from "./Consts.ts";
import IBankinStateO from "./model/IBankinStateO.ts";
import CardPresentation from "./CardPresentation.tsx";
import IBankinError, {GenericItemError} from "../Bankin/IBankinError.ts";
import BankinStatusCode from "../Bankin/BankinStatusCode.ts";
import nonConnectedBankinItemStatusToMessage from "../Bankin/bankinUtils.ts";
import {synchronizeNewAccount} from "./synchronizeNewAccount.ts";
import Error from './Error.tsx';
import {getMostRecent} from "./model/IBankinItem.ts";

// Only to be used if incomplete
export const computeSingleBankinError = (bankinStatus: IBankinStateO): IBankinError | null => {
    if (!bankinStatus) {
        return null;
    }

    // const bankinItems = bankinStatus.items;

    const bankinItemsNonConnected = bankinStatus.items
        .filter((bi) => bi.content.status !== BankinStatusCode.Ok);

    // if (bankinStatus.connected) {
    //     return null;
    // }

    if (!bankinItemsNonConnected || bankinItemsNonConnected.length === 0) {
        return null;
    }

    if (bankinItemsNonConnected.length === 1) {
        const bankinItemStatus = nonConnectedBankinItemStatusToMessage.get(getMostRecent(bankinItemsNonConnected).content.status);
        if (!!bankinItemStatus) {
            return bankinItemStatus;
        }
    }

    /*
    if (BankItems.filter((bi) => bi.Content.Status === BankinStatusCode.Ok).length > 0) {
        return NoTransactionsError;
    }
    */

    return GenericItemError;
};

const BankinIncompleteNonLoading = (
    {
        bankinStatus
    }: {
        bankinStatus: IBankinStateO;
    }
) => {
    const singleBankinError = computeSingleBankinError(bankinStatus);
    return (
        /* preItemError
                            ? <Error title={GenericItemError.title}
                                        message={GenericItemError.message} />
                            : */ !!singleBankinError
            ? <Error title={singleBankinError.title}
                     message={singleBankinError.message} />
            : <CardPresentation className={null} />
    );
}

const NonCompleted = (
    {
        isRefreshing,
        connectNewItemIsLoading,
        bankinStatus,

        syncButtonText,
        syncButtonKind,
        syncButtonSize,

        refresh,
        connectNewItem
    }: {
        isRefreshing: boolean;
        connectNewItemIsLoading: boolean;
        bankinStatus: IBankinStateO;

        syncButtonText: string;
        syncButtonKind: ButtonKind;
        syncButtonSize: ButtonSize;

        refresh: () => void;
        connectNewItem: () => void;
    }
) =>
    <div>
        { isRefreshing || connectNewItemIsLoading
            ? <div className='p-ok-message p-padding-6'
                   style={{display: 'flex' /* implicit row */}}>
                <div className='p-no-flex p-icon p-ok-border p-ok-text p-margin-top-8'>!</div>
                <div className='p-margin-left-6'>
                    <div className='p-bold' style={{lineHeight: '28px'}}>
                        Un peu de patience:
                    </div>
                    <div>Votre compte bancaire est en cours de traitement..</div>
                </div>
            </div> : <BankinIncompleteNonLoading bankinStatus={bankinStatus}/>
        }
        <div className='p-right p-margin-top-4'>
            <Button id={SynchronizeBankinId}
                    kind={syncButtonKind}
                    size={syncButtonSize}
                    text={syncButtonText}
                    actionO={(!isRefreshing && !connectNewItemIsLoading && (() => synchronizeNewAccount(
                        bankinStatus,
                        refresh,
                        connectNewItem,
                    ))) || null /* (!completed && !synchronizing) ? click : null */}
                    isLoading={connectNewItemIsLoading /* clickIsLoading */}/>
        </div>
    </div>;

export default NonCompleted;