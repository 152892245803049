import {getCustomerToken} from "factor-lib/customerBankin/customerTokenStorageUtils.ts";
import Callback from "./Callback.tsx";
import ISynchronizationResult from "factor-lib/customerBankin/ISynchronizationResult.ts";
import {getBankinFactorContext} from "../../contexts/IBankinFactorContext.ts";
import IIncompleteBankinItemErrorParams from "./IIncompleteBankinItemErrorParams.ts";
import {IBankinItemContentDto} from "factor-lib/customerBankin/IBankinItemContentDto.ts";
import IIncompleteBankinPreItemErrorParams from "./IIncompleteBankinPreItemErrorParams.ts";
import {axiosPut2, defaultSpecificErrorHandlerAsync} from "factor-lib/authServer/axiosWrapper.ts";
import axiosConfig from "../../axiosConfig.tsx";
import extractErrorMessage from "factor-lib/Errors/extractErrorMessage.ts";
import {useContext} from "react";
import GlobalMessageContext from "../../reactcontext/GlobalMessageContext.tsx";
import {navigate} from "factor-lib/contexts/navigateWrapper.ts";

const completeErrorUrl = `/complete-error`;

// // Why does this need to be duplicated ?
// declare global {
//     interface Window {
//         bankinOnPreItemError?: (step: string) => Promise<void>;
//         bankinOnItemError?: (itemId: number, step: string | null, baninItem: IBankinItemContentDto) => Promise<void>;
//         bankinOnSuccess?: (itemId: number, r: ISynchronizationResult) => Promise<void>;
//     }
// }

const redirectToMainAsync = (customerToken: string): Promise<void> =>
    navigate(`/${customerToken}`);

const onItemSuccess = (
    authToken: string,
    messageHandler: (message: string) => void,
    itemId: number
): void => {
    axiosPut2<ISynchronizationResult>(
        `${getBankinFactorContext().backendBaseUrl}/items/${itemId}/complete-success`,
        undefined,
        axiosConfig(authToken),
        (p) => p,
        defaultSpecificErrorHandlerAsync
    )
        // .then((r) => {
        //     const opener = (window.opener as Window);
        //     const { bankinOnSuccess } = opener;
        //     if (!!bankinOnSuccess) {
        //         return bankinOnSuccess(itemId, r);
        //     }
        // })
        .catch((e: Error) =>
            messageHandler(extractErrorMessage(false, e))
        )
        .finally(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            redirectToMainAsync(authToken);
        });
}

const onItemError = (
    authToken: string,
    messageHandler: (message: string) => void,
    itemId: number,
    step: string | null
): void => {
    const incompleteError: IIncompleteBankinItemErrorParams = ({
        step
    });
    axiosPut2<IBankinItemContentDto>(
        `${getBankinFactorContext().backendBaseUrl}/items/${itemId}${completeErrorUrl}`,
        incompleteError,
        axiosConfig(authToken),
        (p) => p,
        defaultSpecificErrorHandlerAsync
    )
        // .then((r) => {
        //     const opener = (window.opener as Window);
        //     const { bankinOnItemError } = opener;
        //     if (!!bankinOnItemError) {
        //         return bankinOnItemError(itemId, step, r);
        //     }
        // })
        .catch((e: Error) =>
            messageHandler(extractErrorMessage(false, e))
        )
        .finally(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            redirectToMainAsync(authToken);
        });
}

const onPreItemError = (
    authToken: string,
    messageHandler: (message: string) => void,
    step: string | null,
): void => {
    if (!!step) {
        const incompleteError: IIncompleteBankinPreItemErrorParams = ({
            step
        });
        axiosPut2(
            `${getBankinFactorContext().backendBaseUrl}${completeErrorUrl}`,
            incompleteError,
            axiosConfig(authToken),
            (p) => p,
            defaultSpecificErrorHandlerAsync
        )
            // .then((/* r */ ) => {
            //     const opener = (window.opener as Window);
            //     const { bankinOnPreItemError } = opener;
            //     if (!!bankinOnPreItemError) {
            //         return bankinOnPreItemError(step);
            //     }
            // })
            .catch((e: Error) =>
                messageHandler(extractErrorMessage(false, e))
            )
            .finally(() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                redirectToMainAsync(authToken);
            });
    } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        redirectToMainAsync(authToken);
    }
}

const BankinCallbackWithCustomerTokenWrapper = () => {
    // const logger: ILogger = getBankinFactorContext().logger;
    const customerToken = getCustomerToken()!;// from storage

    // const authToken: string = useContext<string | null>(AuthTokenContext)!;
    // Coming from parent
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    const messageHandler: (message: string) => void = useContext<((message: string) => void) | undefined>(GlobalMessageContext)!;

    return (
        <Callback onItemSuccess={(itemId: number) => onItemSuccess(customerToken, messageHandler, itemId)}
                  onItemError={(itemId: number, step: string | null) => onItemError(customerToken, messageHandler, itemId, step)}
                  onPreItemError={(step: string | null) => onPreItemError(customerToken, messageHandler, step)}
        />
    );
}

export default BankinCallbackWithCustomerTokenWrapper;