import ILogger from "./ILogger";
import {ICustomProperties} from "@microsoft/applicationinsights-core-js";
import ILoggerLevel from "./ILoggerLevel.ts";

export default class ConsoleLogger implements ILogger {

    private static log(message: string) {
        console.log(`[Logger] : ${message}`);
    }

    public static readonly Instance: ConsoleLogger = new ConsoleLogger();

    public trackException(error: Error): void {
        console.error(error);
        // throw error; // on ne peut pas rethrower : on perturbe le flow
    }

    public trackTrace(severityLevel: ILoggerLevel, message: string, customProperties?: ICustomProperties): void {
        const fullMessage = `${message}${!!customProperties ? ` (${JSON.stringify(customProperties)})` : ''}`;
        if (severityLevel >= ILoggerLevel.Error) {
            console.error(message);
        } else if (severityLevel === ILoggerLevel.Warning) {
            console.warn(message);
        } else {
            ConsoleLogger.log(`[${severityLevel} : ${fullMessage}]`);
        }
    }

    public setAuthenticatedUserContext(userId: string, customerId: string | null, storeInCookie: boolean): void {
        ConsoleLogger.log(`Logging user : ${userId} of company ${customerId} ${storeInCookie ? 'with' : 'without'} cookies`);
    }

    public clearAuthenticatedUserContext(): void {
        ConsoleLogger.log('Logged out');
    }
}