import type {To} from "@remix-run/router";
import type {NavigateOptions} from "react-router/dist/lib/context";

// A wrapper around router.navigate()
// to bypass non-hook useNavigate(),
// which causes issues like https://github.com/remix-run/react-router/issues/7634
const navigateWrapper: {
    value: ((to: To, options?: NavigateOptions) => Promise<void>) | null;
} = {
    value: null
};

export const navigateVoid = (to: To, options?: NavigateOptions): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(to, options);
}

export const navigate = (to: To, options?: NavigateOptions): Promise<void> => {
    if (!!navigateWrapper.value) {
        return navigateWrapper.value(to, options);
    }
    throw new Error('No navigation method has been set');
};

export default navigateWrapper;