import {useContext, useEffect, useState} from "react";
import Loader from "factor-lib/Loader";
import slowLoadingMessage from 'factor-lib/serverUtils/slowLoadingMessage';
import IBankinStateO from "factor-lib/customerBankin/model/IBankinStateO.ts";
import AuthTokenContext from "../../reactcontext/AuthTokenContext.tsx";
import {axiosGet2, defaultSpecificErrorHandlerAsync} from "factor-lib/authServer/axiosWrapper.ts";
import {getBankinFactorContext} from "../../contexts/IBankinFactorContext.ts";
import extractErrorMessage from "factor-lib/Errors/extractErrorMessage.ts";
import GlobalMessageContext from "../../reactcontext/GlobalMessageContext.tsx";
import axiosConfig from "../../axiosConfig.tsx";
import ILoadable, {Loading} from "factor-lib/ILoadable.ts";
import IBankinStarted from "factor-lib/customerBankin/model/IBankinStarted.ts";
import AppLoaded from "./AppLoaded.tsx";

const BankinLoader = () =>
    <div className='p-vertical-center'>
        <span className='p-margin-bottom-4'> { slowLoadingMessage } </span>
        <Loader />
    </div>;


export const statusFullUrl = () =>
    `${getBankinFactorContext().backendBaseUrl}/status`;

const App = () => {
    const customerToken: string = useContext<string | null>(AuthTokenContext)!;
    const [bankinStatus, setBankinStatus] = useState<ILoadable<{value: IBankinStateO}>>(Loading);

    const messageHandler: (message: string) => void = useContext<((message: string) => void) | undefined>(GlobalMessageContext)!;

    useEffect(() => {
        /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
        axiosGet2<IBankinStateO>(
            statusFullUrl(),
            axiosConfig(customerToken),
            (p) => p,
            defaultSpecificErrorHandlerAsync
        )
            .then((r: IBankinStateO) => setBankinStatus({
                loaded: {
                    value: r
                }
            }))
            .catch((e: Error) =>
                messageHandler(extractErrorMessage(false, e))
            )
        ;
    }, [customerToken, messageHandler]);

    return (
        <div className='p-padding-3'>
            {!bankinStatus.loaded
                ? <BankinLoader />
                : <AppLoaded bankinStatus={bankinStatus.loaded.value}
                             setBankinStatus={(newBankinStarted: IBankinStarted) => setBankinStatus({
                                 loaded: {
                                     value: newBankinStarted
                                 }
                             })}
                />
            }
        </div>
    );
}

export default App;
