import {setLibFactorContext} from "factor-lib/contexts/ILibFactorContext.ts";
import ILogger from "factor-lib/services/logger/ILogger.ts";
import {Axios} from "axios";
import {setBankinFactorContext} from "./IBankinFactorContext.ts";

const setAllContexts = (
    logger: ILogger,
    showUnexpectedError: boolean,
    staticAxios: Axios,
    backendBaseUrl: string
) => {
    setLibFactorContext(logger, showUnexpectedError, staticAxios);
    setBankinFactorContext(backendBaseUrl);
}

export default setAllContexts;