import BankinStatusCode from "../Bankin/BankinStatusCode.ts";
import IBankinItem from "./model/IBankinItem.ts";

export const StatusLoadedId = 'statusLoadedId'

export const checkItems = (
    items: IBankinItem[],
    setDisplayExistingItemsModal: (newDisplayExistingItemsModal: IBankinItem[]) => void,
    connectNewItem: () => void
): void => {
    if (!!items &&
        items.filter((bi: IBankinItem) => bi.content.status !== BankinStatusCode.Ok).length > 0
    ) {
        setDisplayExistingItemsModal(items);
        return;
    } // else
    connectNewItem();
}
