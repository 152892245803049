import React from 'react';
// import reportWebVitals from './reportWebVitals';

import GlobalMessageWrapper from "./reactcontext/GlobalMessageWrapper";
import {RouterProvider} from "react-router-dom";
import { createRoot } from "react-dom/client";

import router from "./router";

import setAllContextsFromEnv from "./contexts/setAllContextsFromEnv.ts";

import './index.scss';

/* const debug = */ setAllContextsFromEnv();

const throwRootNotFound = (): HTMLElement => {
    throw new Error('Cannot find root');
}

createRoot(document.getElementById('root') ?? throwRootNotFound()).render(
    <React.StrictMode>
        <GlobalMessageWrapper>
            <RouterProvider router={router} />
        </GlobalMessageWrapper>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
