import {checkItems, StatusLoadedId} from "factor-lib/customerBankin/StatusLoaded.tsx";
import IBankinStarted from "factor-lib/customerBankin/model/IBankinStarted.ts";
import axiosConfig from "../../axiosConfig.tsx";
import {getBankinFactorContext} from "../../contexts/IBankinFactorContext.ts";
import IBankinStateO from "factor-lib/customerBankin/model/IBankinStateO.ts";
import {useContext, useState} from "react";
import AuthTokenContext from "../../reactcontext/AuthTokenContext.tsx";
import IBankinItem from "factor-lib/customerBankin/model/IBankinItem.ts";
import {axiosGet2, axiosPut2, defaultSpecificErrorHandlerAsync} from "factor-lib/authServer/axiosWrapper.ts";
import extractErrorMessage from "factor-lib/Errors/extractErrorMessage.ts";
import GlobalMessageContext from "../../reactcontext/GlobalMessageContext.tsx";
import {KIND_OUTLINED, SIZE_FIXED} from "factor-lib/Buttons/Button.tsx";
import NonCompleted from "factor-lib/customerBankin/NonCompleted.tsx";
import Completed from "factor-lib/customerBankin/Completed.tsx";
import {synchronizeNewAccount} from "factor-lib/customerBankin/synchronizeNewAccount.ts";
import ExistingItemsModal from "factor-lib/customerBankin/ExistingItemsModal.tsx";
import isBankinComplete from "factor-lib/customerBankin/isBankinComplete.ts";

const refreshingUrl = `/refresh`;
const newItemConnectUrl = `/connect`;
const existingConnectUrl = (itemId: number) => `/connect/${itemId}`;

const refresh = (
    authToken: string,
    messageHandler: (message: string) => void,
    setBankinStatus: (newBankItems: IBankinItem[]) => void,
    setSynchronizing: (newValue: boolean) => void,
    setDisplayExistingItemsModal: (newDisplayExistingItemsModal: IBankinItem[]) => void,
    setConnectNewItemIsLoading: (newConnectNewItemIsLoading: boolean) => void
): void => {
    setSynchronizing(true);
    axiosPut2<IBankinItem[]>(
        `${getBankinFactorContext().backendBaseUrl}${refreshingUrl}`,
        null,
        axiosConfig(authToken),
        (p) => p,
        defaultSpecificErrorHandlerAsync
    )
        .finally(() => setSynchronizing(false))
        .then((items: IBankinItem[]) => {
            setBankinStatus(items);
            return checkItems(
                items,
                setDisplayExistingItemsModal,
                () => connectNewItem(
                    authToken,
                    setConnectNewItemIsLoading,
                    messageHandler,
                    null
                )
            );
        })
        .catch((e: Error) =>
            messageHandler(extractErrorMessage(false, e))
        );
}

const connectNewItem = (
    authToken: string,
    setConnectNewItemIsLoading: (newConnectNewItemIsLoading: boolean) => void,
    messageHandler: (message: string) => void,
    initBankinUser: (() => void) | null
): void => {
    setConnectNewItemIsLoading(true);

    axiosGet2<string>(
        `${getBankinFactorContext().backendBaseUrl}${newItemConnectUrl}`,
        // `${getBankinFactorContext().backendBaseUrl}${newItemConnectUrl}`,
        axiosConfig(authToken),
        // axiosConfig(authToken),
        (p) => p,
        defaultSpecificErrorHandlerAsync
    )
        .finally(() => setConnectNewItemIsLoading(false))
        .then((response: string) => {
            setConnectNewItemIsLoading(true);
            if (!!initBankinUser) {
                initBankinUser();
            }
            openBankinConnectUrl(
                // navigator,
                response
            );
        })
        .catch((e: Error) =>
            messageHandler(extractErrorMessage(false, e))
        );
}

const openBankinConnectUrl = (
    // navigator: INavigator,
    url: string
): void => {
    // TODO : reset all the time :'(
    // navigator.goTo(url);
    window.location.assign(url);
}

const connectExistingItem = (
    itemId: number,
    authToken: string,
    // axiosConfig: AxiosRequestConfig,
    messageHandler: (message: string) => void,
    setConnectExistingItemIsLoading: (newConnectExistingItemIsLoading: boolean) => void
    // itemId: number
): void => {

    // TODO : switch to react-query as some point
    setConnectExistingItemIsLoading(true);

    axiosGet2<string>(
        `${getBankinFactorContext().backendBaseUrl}${existingConnectUrl(itemId)}`,
        axiosConfig(authToken),
        (p) => p,
        defaultSpecificErrorHandlerAsync
    )
        .finally(() => setConnectExistingItemIsLoading(false))
        .then(openBankinConnectUrl)
        .catch((e: Error) =>
            messageHandler(extractErrorMessage(false, e))
        );
}

export const completeId = 'completeId';

const AppLoaded = (
    {
        bankinStatus,
        setBankinStatus
    }: {
        bankinStatus: IBankinStateO;
        setBankinStatus: (newBankinStarted: IBankinStarted) => void;
    }
) => {
    const authToken: string = useContext<string | null>(AuthTokenContext)!;

    // TODO : use react-query

    const [connectNewItemIsLoading, setConnectNewItemIsLoading] = useState<boolean>(false);
    const [connectExistingItemIsLoading, setConnectExistingItemIsLoading] = useState<boolean>(false);
    const [synchronizing, setSynchronizing] = useState(false);

    // /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    const messageHandler: (message: string) => void = useContext<((message: string) => void) | undefined>(GlobalMessageContext)!;

    const [displayExistingItemsModal, setDisplayExistingItemsModal] = useState<IBankinItem[] | null>(null);

    const connectedItemsWithTransactions = isBankinComplete(bankinStatus);

    return (
        <div className='container'>
            <div id={StatusLoadedId}>
                {!connectedItemsWithTransactions
                    ? <NonCompleted isRefreshing={synchronizing}
                                    connectNewItemIsLoading={connectNewItemIsLoading}
                                    bankinStatus={bankinStatus}

                                    syncButtonText='Synchroniser'
                                    syncButtonKind={KIND_OUTLINED}
                                    syncButtonSize={SIZE_FIXED}
                                    refresh={() => refresh(
                                        authToken,
                                        messageHandler,
                                        (newBankItems: IBankinItem[]) => setBankinStatus({items: newBankItems}),
                                        setSynchronizing,
                                        setDisplayExistingItemsModal,
                                        setConnectNewItemIsLoading
                                    )}
                                    connectNewItem={() => connectNewItem(
                                        authToken,
                                        setConnectNewItemIsLoading,
                                        messageHandler,
                                        !bankinStatus ? (() => setBankinStatus({items: []})) : null,
                                    )}/>
                    : <Completed connectedItemsWithTransactions={connectedItemsWithTransactions}
                                 isRefreshing={synchronizing}
                                 connectNewItemIsLoading={connectNewItemIsLoading}
                                 synchronizeNewAccount={() => synchronizeNewAccount(
                                     bankinStatus,
                                     () => refresh(
                                         authToken,
                                         messageHandler,
                                         (newBankItems: IBankinItem[]) => setBankinStatus({items: newBankItems}),
                                         setSynchronizing,
                                         setDisplayExistingItemsModal,
                                         setConnectNewItemIsLoading
                                     ),
                                     () => connectNewItem(
                                         authToken,
                                         setConnectNewItemIsLoading,
                                         messageHandler,
                                         !bankinStatus ? (() => setBankinStatus({items: []})) : null,
                                     )
                                 )}>
                        <span>
                            <div id={completeId}>Vous pouvez à présent fermer cet onglet ou en connecter de nouveaux.</div>
                            <div> Merci de la part de l’équipe Dimpl. </div>
                        </span>
                    </Completed>
                }

                {displayExistingItemsModal &&
                    <ExistingItemsModal existingItems={displayExistingItemsModal}
                                        close={() => setDisplayExistingItemsModal(null)}
                                        connectNewItem={() => connectNewItem(
                                            authToken,
                                            setConnectNewItemIsLoading,
                                            messageHandler,
                                            !bankinStatus ? (() => setBankinStatus({items: []})) : null,
                                        )}
                                        connectExistingItem={(itemId: number) => connectExistingItem(
                                            itemId,
                                            authToken,
                                            messageHandler,
                                            setConnectExistingItemIsLoading
                                        )}
                                        connectExistingItemIsLoading={connectExistingItemIsLoading}/>
                }
            </div>
        </div>
    );
}

export default AppLoaded;