// Returns specific message, which may be hidden for the user
import {DefaultGenericUnexpectedErrorMessage, LongGenericUnexpectedErrorMessage} from "../errorsUtils.ts";
import {getLibFactorContext} from "../contexts/ILibFactorContext.ts";
import HandledError from "./HandledError.ts";

const extractUnexpectedErrorMessage2 = (
    error: Error
) => {
    getLibFactorContext().logger.trackException(error);
    return error.message;
}

// returns message for user
const extractUnexpectedErrorMessage = (
    formatLongResponse: boolean,
    error: Error
) =>
    getLibFactorContext().showUnexpectedError
        ? extractUnexpectedErrorMessage2(error)
        : (formatLongResponse ? LongGenericUnexpectedErrorMessage : DefaultGenericUnexpectedErrorMessage);

// returns message for user
const extractErrorMessage = (
    formatLongResponse: boolean,
    error: Error
): string => {
    if (error instanceof HandledError) {
        return error.messageForUser;
    }

    // else unexpected

    return extractUnexpectedErrorMessage(
        formatLongResponse,
        error
    ); // extractAxiosErrorMessage(result.error, displayFullError ? null : { displayShortDefaultError: false });
}

export default extractErrorMessage;