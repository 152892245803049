
// Only to be used to wrap promises,
// when we cannot use useQuery()
// For now, only used in MsalProvider
export default interface ILoadable<T> {
    loaded: T | null; // null -> loading
}

export const Loading = ({
    loaded: null
});

export const mapLoadable = <T, U> (s : ILoadable<T>, mapper: (s: T) => U): ILoadable<U> =>
    !!s.loaded ? ({
        loaded: mapper(s.loaded)
    }) : ({
        loaded: null
    });

export const flatMapLoadable = <T, U> (s : ILoadable<T>, mapper: (s: T) => ILoadable<U>): ILoadable<U> =>
    !!s.loaded ? mapper(s.loaded) : ({
        loaded: null
    });