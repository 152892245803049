import IBankinItemContent from "../../Bankin/IBankinItemContent.ts";
import {comparingDate, serverDateDeserialization} from "../../utils/dateUtils.ts";

export default interface IBankinItem {
    id: number;
    dateTime: string;
    content: IBankinItemContent;
}

export const getMostRecent = (items: IBankinItem[] /* non empty*/): IBankinItem =>
    items.sort(comparingDate(p => serverDateDeserialization(p.dateTime)))[items.length - 1];