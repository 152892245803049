import {ensureNonNullEnvName, envError, getEnv} from "factor-lib/utils/envUtils.ts";
import setAllContexts from "./setAllContexts.ts";
import axios from "axios";
import IBankinEnvConfig from "../env/IBankinEnvConfig.tsx";
import LocalEnv from "../env/LocalEnv.ts";
import DevEnv from "../env/DevEnv.ts";
import DemoEnv from "../env/DemoEnv.ts";
import ProdEnv from "../env/ProdEnv.ts";

const nonTestEnv = (): IBankinEnvConfig => {
    const currentConfigO: IBankinEnvConfig | null = getEnv(() => ensureNonNullEnvName(import.meta.env.VITE_ENV_NAME as string | undefined), LocalEnv, DevEnv, DemoEnv, ProdEnv);
    return !!currentConfigO ? currentConfigO : envError();
}

const currentConfigEnv: IBankinEnvConfig =
    import.meta.env.NODE_ENV !== 'test'
        ? nonTestEnv()
        : null as unknown as IBankinEnvConfig; // TODO : improve

const setAllContextsFromEnv = () =>
    setAllContexts(
        currentConfigEnv.logger(),
        currentConfigEnv.debug,
        axios,
        currentConfigEnv.backendUrl
    );

export default setAllContextsFromEnv;