import { ReactComponent as Close } from './Close.svg';
import {CSSProperties} from "react";

import './closebutton.scss';

const CloseButton = (
    {
        className,
        style,
        clickHandler
    }: {
        className?: string,
        style?: CSSProperties,
        clickHandler: (/* e: MouseEvent */) => void
    }
) =>
    <div className={`${!!className ? `${className} ` : ''}p-clickable p-vertical-center p-closebutton`}
         style={style}
         aria-label='close'
         onClick={clickHandler}>
        <Close />
    </div>;

export default CloseButton;
