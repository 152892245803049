import { useEffect } from "react";
import ILoggerLevel from "factor-lib/services/logger/ILoggerLevel.ts";
import Loader from "factor-lib/Loader.tsx";
import {getLibFactorContext} from "factor-lib/contexts/ILibFactorContext.ts";

export const OkSuccessValue = 'true';

const callback = (
    itemIdStr: string | null,
    success: string | null,
    step: string | null,
    onItemSuccess: (itemId: number) => void,
    onItemError: (itemId: number, step: string | null) => void,
    onPreItemError: (step: string | null) => void
): void => {
    // const itemIdStr = query.item_id as string | null;
    const isSuccess: boolean = OkSuccessValue === (success /* query.success as string | undefined */);
    const p = ({
        itemId: itemIdStr,
        success: isSuccess,
        step
    });
    getLibFactorContext().logger.trackTrace(ILoggerLevel.Information, `Bankin callback : ${window.location.search}`, p);
    return callbackWoMarketing(
        isSuccess,
        itemIdStr,
        step,
        onItemSuccess,
        onItemError,
        onPreItemError
    );
}

const callbackWoMarketing = (
    success: boolean,
    itemIdStr: string | null,
    step: string | null,
    onItemSuccess: (itemId: number) => void,
    onItemError: (itemId: number, step: string | null) => void,
    onPreItemError: (step: string | null) => void
): void => {
    return synchronizationCompletion(
        success,
        itemIdStr,
        step,
        onItemSuccess,
        onItemError,
        onPreItemError
    );
}

const synchronizationCompletion = (
    success: boolean,
    itemIdStr: string | null,
    step: string | null,
    onItemSuccess: (itemId: number) => void,
    onItemError: (itemId: number, step: string | null) => void,
    onPreItemError: (step: string | null) => void
): void => {
    if (success) {
        // localhost:1893/item_id=3273161&user_uuid=d6cba86a-a091-49d2-89ec-51f9d8d2b72c&success=true
        // In background
        return onItemSuccess(
            parseItemId(itemIdStr!)
        );
    } // else

    if (!!itemIdStr) {
        return onItemError(
            parseItemId(itemIdStr),
            step
        );
    } // else
    return onPreItemError(
        step
    );
}

const parseItemId = (itemIdStr: string): number =>
    parseInt(itemIdStr, 10);

const CallbackParsedParameters = (
    {
        itemIdStr,
        success,
        step,

        onItemSuccess,
        onItemError,
        onPreItemError
    }: {
        itemIdStr: string | null;
        success: string | null;
        step: string | null;

        onItemSuccess: (itemId: number) => void;
        onItemError: (itemId: number, step: string | null) => void;
        onPreItemError: (step: string | null) => void;
    }
) => {
    // called twice locally

    useEffect(() => {
        callback(
            itemIdStr,
            success,
            step,
            onItemSuccess,
            onItemError,
            onPreItemError
        );
    });

    return (
        <Loader />
    );
};

export default CallbackParsedParameters;
