import BankinStatusCode from "../Bankin/BankinStatusCode.ts";
import IBankinItem from "./model/IBankinItem.ts";
import IBankinStateO from "./model/IBankinStateO.ts";

const isBankinComplete = (bankinStatus: IBankinStateO): IBankinItem[] /* non empty */ | null => {
    const connectedItemsWithTransactions: IBankinItem[] | null = !!bankinStatus
        ? bankinStatus.items.filter((bi) => bi.content.status === BankinStatusCode.Ok && bi.content.hasAnyTransaction) // Not exactly as getItemsWithTransactions. Why ?
        : null;

    return !(!connectedItemsWithTransactions || connectedItemsWithTransactions.length === 0)
        ? connectedItemsWithTransactions
        : null;
}

export default isBankinComplete;