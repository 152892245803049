import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import ILogger from './ILogger';
import {ICustomProperties} from "@microsoft/applicationinsights-core-js";
import {ReactPlugin} from "@microsoft/applicationinsights-react-js";
import ILoggerLevel from "./ILoggerLevel.ts";

// https://docs.microsoft.com/fr-fr/azure/azure-monitor/app/api-custom-events-metrics
// https://github.com/microsoft/ApplicationInsights-JS#readme
export default class TelemetryLogger implements ILogger {

    private appInsights: ApplicationInsights;

    constructor(instrumentationKey: string) {
        /*
        // https://github.com/Microsoft/ApplicationInsights-JS/issues/567
        if (AppInsights.downloadAndSetup !== undefined) {
            AppInsights.downloadAndSetup({ instrumentationKey: insightInstrumentationKey });
        } */
        // https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
        const reactPlugin = new ReactPlugin();

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                enableAutoRouteTracking: true,
                extensions: [reactPlugin]
            }
        });
        this.appInsights.loadAppInsights();
        // Looks not necessary with React plugin
        // this.appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
    }

    public setAuthenticatedUserContext(userId: string, customerId: string | null, storeInCookie = false){
        this.appInsights.setAuthenticatedUserContext(userId, customerId ?? undefined, storeInCookie);
    }
    public clearAuthenticatedUserContext(){
        this.appInsights.clearAuthenticatedUserContext();
    }

    public trackException(exception: Error, customProperties?: ICustomProperties): void {
        this.appInsights.trackException({exception, severityLevel: ILoggerLevel.Error /* default ? */}, customProperties);
    }

    public trackTrace(severityLevel: ILoggerLevel, message: string, customProperties?: ICustomProperties): void {
        this.appInsights.trackTrace({ message, severityLevel }, customProperties);
    }
}
