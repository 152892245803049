import {throwUnknownContextError} from "factor-lib/contexts/ILibFactorContext.ts";

// different from currentEnv, as does not rely on implementations
export default interface IBankinFactorContext {
    backendBaseUrl: string;
}

let wrapper: IBankinFactorContext | null = null;

export const setBankinFactorContext = (
    backendBaseUrl: string
): void => {
    wrapper = ({
        backendBaseUrl
    });
}

export const getBankinFactorContext = (): IBankinFactorContext =>
    wrapper ?? throwUnknownContextError();