import demoConfigFactory, {TelemetryCustomerInstrumentationKey} from "factor-lib/envs/demoConfigFactory";
import IBankinEnvConfig from "./IBankinEnvConfig";

const DemoEnv: IBankinEnvConfig = ({
    ...demoConfigFactory(
        TelemetryCustomerInstrumentationKey
        // 'https://bankin.sandbox.dimpl.io' // 'https://nice-field-0f09b3303.1.azurestaticapps.net'
    ),
    backendUrl: 'https://factor-demo-bankin-function.azurewebsites.net',
    debug: true
});

export default DemoEnv;
