import Modal from "../Modal";
import Button, {IButtonProps} from "../Buttons/Button";
import { ReactNode } from "react";

export const WithMainButtonModalContent = (
    {
        buttonsProps,
        children
    }: {
        buttonsProps: IButtonProps;
        children: ReactNode;
    }
) =>
    <div className='p-padding-top-3 p-padding-bottom-4 p-padding-left-4 p-padding-right-4 p-vertical-center'>
        <div className='p-margin-bottom-5'>
            { children }
        </div>
        <Button {...buttonsProps} />
    </div>;

const WithMainButtonModal = (
    {
        className,
        close,
        maxWidth,
        fullMaxWidth,
        buttonsProps,
        children
    }: {
        className?: string;
        close: (() => void) | null;
        maxWidth: string | null;
        fullMaxWidth: boolean;
        buttonsProps: IButtonProps;
        children: ReactNode;
    }
) =>
    <Modal id='ReadOnlyModal'
           className={className}
           active={true}
           close={close ? close : undefined}
           maxWidth={maxWidth}
           fullMaxWidth={fullMaxWidth}>
        <WithMainButtonModalContent buttonsProps={buttonsProps}>
            { children }
        </WithMainButtonModalContent>
    </Modal>;


export default WithMainButtonModal;
