
// Timeout specs : PRED-2045

export const DefaultAxiosTimeoutMilliseconds = 5_000; // For user, not admin

export const FileUploadTimeoutMilliseconds = 100_000; // PRED-2228
export const CallWithDimplScoreInBackTimeoutMilliseconds = 3_000 /* Extra */ + DefaultAxiosTimeoutMilliseconds;

export const FunctionBackendTimeoutMilliseconds = 30_000;

