import {useRouteError} from "react-router-dom";
import {useEffect} from "react";
import UnexpectedErrorRenderer from "./UnexpectedErrorRenderer.tsx";
import {getLibFactorContext} from "../contexts/ILibFactorContext.ts";
import ILoggerLevel from "../services/logger/ILoggerLevel.ts";

// TODO : delete all other instances
const ErrorRouterHandler = () => {
    // error here will be 'swallowed' and not reach 'root', which would be handled by telemetry js
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const routeError: any = useRouteError();
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    const error: Error | undefined = routeError instanceof Error ? routeError : routeError.error as Error | undefined /* react-router specific, for invalid url for example */;
    const errorMessage: string = (routeError.message ?? routeError.data /* react-router specific, for invalid url for example */) as string;

    const factorContext = getLibFactorContext();

    const logger = factorContext.logger;

    /* eslint-enable */
    useEffect(() => {
        if (!!error) {
            logger.trackException(error);
        } else {
            logger.trackTrace(ILoggerLevel.Error, errorMessage);
        }
    }, [logger, error, errorMessage]);
    return (
        <div className='p-padding-2'>
            <div className='container'>
                <UnexpectedErrorRenderer showUnexpectedError={factorContext.showUnexpectedError}
                                         unexpectedErrorMessage={errorMessage}/>
            </div>
        </div>
    )
};

export default ErrorRouterHandler;