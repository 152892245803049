import { useSearchParams } from "react-router-dom";
import CallbackParsedParameters from "./CallbackParsedParameters.tsx";

// ex : http://127.0.0.1:3000/callback?source=connect&success=false&user_uuid=d093022a-25d7-453e-8eb2-fc402ad88bbc&step=highlighted_banks
const Callback = (
    {
        onItemSuccess,
        onItemError,
        onPreItemError
    }: {
        onItemSuccess: (itemId: number) => void;
        onItemError: (itemId: number, step: string | null) => void;
        onPreItemError: (step: string | null) => void;
    }
) => {
    const [searchParams] = useSearchParams();

    return (
        <CallbackParsedParameters itemIdStr={searchParams.get('item_id')}
                                  step={searchParams.get('step')}
                                  success={searchParams.get('success')}
                                  onItemSuccess={onItemSuccess}
                                  onItemError={onItemError}
                                  onPreItemError={onPreItemError} />
    );
};

export default Callback;
