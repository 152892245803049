import {BankinExistingItemsModalId, synchronizationButtonId} from "./ExistingItemsModalConsts.ts";
import Modal from "../Modal.tsx";
import BankinStatusCode from "../Bankin/BankinStatusCode.ts";
import Button from "../Buttons/Button.tsx";
import Link from "../Link.tsx";
import IBankinItem from "./model/IBankinItem.ts";

const ExistingItemsModal = (
    {
        existingItems,
        close,
        connectNewItem,
        connectExistingItem,
        connectExistingItemIsLoading
    }: {
        existingItems: IBankinItem[]; // length > 0;
        close: () => void;
        connectNewItem: () => void;
        connectExistingItem: (itemId: number) => void;
        connectExistingItemIsLoading: boolean
    }
) =>
    <Modal id={BankinExistingItemsModalId}
            maxWidth='600px'
            fullMaxWidth={false}
            close={close}>
        <div className='p-padding-4 p-vertical-center'>
            <div className='p-bold p-margin-bottom-4'>
                Comptes synchronisés
            </div>
            <ul className='p-full-width p-padding-left-5 p-padding-right-5'>
                { existingItems.map((existingItem, index) => (
                    // TODO : remove columns, use level, or even table for alignment
                    <li key={`tr-${index}`} className='columns'>
                        <div className='column'>
                            <div className='p-horizontal-center p-margin-top-5'>
                                <span className='p-no-flex p-error-icon'>!</span>
                                <span className='p-margin-left-6'>{ existingItem.content.bankName }</span>
                            </div>
                        </div>
                        <div className='column'>
                            <div className='p-margin-top-5'>
                                { existingItem.content.status === BankinStatusCode.Ok
                                    ? existingItem.content.hasAnyTransaction
                                        ? /* why p-horizontal-center */ <span className='p-horizontal-center p-margin-top-5'>Ok</span>
                                        : /* why p-horizontal-center */ <span className='p-bold p-horizontal-center p-margin-top-5'>Transactions non détectées</span>
                                    : <Button id={synchronizationButtonId(existingItem.id)}
                                              className='p-vertical-center'
                                              actionO={() => {
                                                  close();
                                                  connectExistingItem(existingItem.id);
                                              }}
                                              text='Reprendre la connexion'
                                              isLoading={connectExistingItemIsLoading} />
                                }
                            </div>
                        </div>
                    </li>
                ))}

                <li>
                    <div className='p-vertical-center'>
                        <Link id='connectNewItemButtonId'
                              action={{
                                  clickHandler: () => {
                                      close();
                                      connectNewItem();
                                  }
                              }}
                              text='Connecter un autre compte'/>
                    </div>
                </li>
            </ul>
        </div>
    </Modal>;

export default ExistingItemsModal;
