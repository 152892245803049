
export default interface IBankinError {
    title: string;
    message: string;
}

export const LoginFailedError: IBankinError = ({
    title: 'Erreur d’identifiants bancaires',
    message: 'Nous n’avons pas pu connecter le compte bancaire de votre société à cause d’une erreur dans vos identifiants bancaires. Nous vous invitons à réessayer en ayant vos identifiants à disposition.'
});

export const NeedsManualRefreshError: IBankinError = ({
    title: 'Erreur d’authentification forte (SMS ou boitier)',
    message: 'Nous n’avons pas pu connecter le compte bancaire de votre société, à cause d’une étape manquante dans le parcours, probablement l’authentification forte, nécessitant un envoi de code par SMS. Nous vous invitons à réessayer en ayant le téléphone associé au compte à portée de main.'
});

export const ProAccountError: IBankinError = ({
    title: 'Il manque une dernière étape pour connecter votre compte pro',
    message: 'Vous y êtes presque! Cliquez sur “synchroniser” pour finir de connecter votre compte pro.'
});

export const GenericItemError: IBankinError = ({
    title: 'Erreur de synchronisation de vos comptes',
    message: 'Nous n’avons pas pu connecter de compte bancaire de votre société à cause d’une erreur. Vous pouvez reprendre la synchronisation'
});
