import ICoreEnvConfig from "./ICoreEnvConfig";
import configFactory from "./configFactory";
import TelemetryLogger from "../services/logger/TelemetryLogger";

export const TelemetryCustomerInstrumentationKey = '0382e660-dd02-4197-9dbc-ed16663d1190';

export const DemoHostname =
    'recap.sandbox.dimpl.io'; // 'agreeable-mud-07f6fa203.1.azurestaticapps.net' // Do not include http / https

const demoConfigFactory = (
    telemetryInstrumentationKey: string
    // hostname: string
): ICoreEnvConfig =>
    configFactory(
        false,
        true,
        () => new TelemetryLogger(telemetryInstrumentationKey),
        false
        // hostname
    );

export default demoConfigFactory;
