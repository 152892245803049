import {DefaultGenericUnexpectedErrorMessage} from "../errorsUtils.ts";

export const UnexpectedErrorRenderer2 = (
    {
        mainUserErrorMessage,
        extraDetailsO
    }: {
        mainUserErrorMessage: string;
        extraDetailsO: string | null
    }
) =>
    <div className='p-vertical-center'>
        <div className='p-bold'>{mainUserErrorMessage}</div>
        { extraDetailsO &&
            <div className='p-size-7 p-padding-top-7'>{extraDetailsO}</div>
        }
    </div>

// For 'large' section of page
const UnexpectedErrorRenderer = (
    {
        showUnexpectedError,
        unexpectedErrorMessage
    }: {
        showUnexpectedError: boolean;
        unexpectedErrorMessage: string;
    }
) =>
    <UnexpectedErrorRenderer2 mainUserErrorMessage={DefaultGenericUnexpectedErrorMessage}
                              extraDetailsO={(showUnexpectedError && unexpectedErrorMessage) || null}/> ;

export default UnexpectedErrorRenderer;