import ICoreEnvConfig from "./ICoreEnvConfig";
import configFactory from "./configFactory";
import CompositeLogger from "../services/logger/CompositeLogger";
import ConsoleLogger from "../services/logger/ConsoleLogger.ts";
import TelemetryLogger from "../services/logger/TelemetryLogger";

export const DevTelemetryCustomerInstrumentationKey = 'f33cf231-467b-42c2-9ece-c7fd3600d8a1';

export const DevAzureB2CDomain = 'dimplfactortest';
export const DevSigninOrUpFlowName = 'B2C_1_sign-up-or-in';

export const DevTelemetryBuyerInstrumentationKey = '3e0f271b-1986-4cbf-8fd7-821e2802ba8c';

const devConfigFactory = (
    telemetryInstrumentationKey: string
    // hostname: string
): ICoreEnvConfig =>
    configFactory(
        true,
        true,
        () => new CompositeLogger([
            new TelemetryLogger(telemetryInstrumentationKey),
            ConsoleLogger.Instance
        ]),
        false

        /* ,
        hostname */
    );

export default devConfigFactory;
