import {storeCustomerToken} from "factor-lib/customerBankin/customerTokenStorageUtils.ts";
import Loader from "factor-lib/Loader.tsx";
import {useEffect} from "react";
import useUrlQuery from "factor-lib/useUrlQuery.ts";
import {BankinUrlQueryParamName, CustomerParamQueryParamName} from "factor-lib/customerBankin/bankinFromCustomerUrl.ts";

// Duplicated, but does not work in lib
declare global {
    interface Window {
        // TODO : merge these 2 ?
        customerToken?: string;
        bankinUrl?: string;
    }
}

const FromCustomer = () => {
    const query = useUrlQuery();
    useEffect(() => {
        const customerToken = query.get(CustomerParamQueryParamName);
        const bankinUrl = query.get(BankinUrlQueryParamName);
        if (!!customerToken && !!bankinUrl) {
            storeCustomerToken(customerToken);
            location.replace(bankinUrl);
        } // else error ?
    }, [query]);
    return (
        <Loader />
    )
}

export default FromCustomer;