
// different from currentEnv, as does not rely on implementations
import ILogger from "../services/logger/ILogger.ts";
import {Axios} from "axios";

export default interface ILibFactorContext {
    logger: ILogger;
    showUnexpectedError: boolean;
    axios: Axios
}

let wrapper: ILibFactorContext | null = null;

export const setLibFactorContext = (
    logger: ILogger,
    showUnexpectedError: boolean,
    staticAxios: Axios
): void => {
    wrapper = ({
        logger,
        showUnexpectedError,
        axios: staticAxios
    });
}

export const throwUnknownContextError = <T> (): T => {
    throw new Error('No lib context set');
}

export const getLibFactorContext = (): ILibFactorContext =>
    wrapper ?? throwUnknownContextError();