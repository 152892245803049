import ICoreEnvConfig from './ICoreEnvConfig';
import ILogger from "../services/logger/ILogger";
import IntercomLogging from "../services/Intercom/IntercomLogging";
import ConsoleLogger from "../services/logger/ConsoleLogger.ts";

const LocalConfig: ICoreEnvConfig = ({
    storyBookUI: true,
    debug: true,
    logMsalInfo: false, // for now
    logger:
        (): ILogger => ConsoleLogger.Instance,

    intercom:
        () => Promise.resolve(new IntercomLogging()),
});

export default LocalConfig;
