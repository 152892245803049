import ICoreEnvConfig from "./ICoreEnvConfig";
import ILogger from "../services/logger/ILogger";
import loadIntercom from "../services/Intercom/loadIntercom";

const configFactory = (
    debug: boolean,
    storyBookUI: boolean,
    logger: () => ILogger,
    logMsalInfo: false
    // hostname: string, // For axeptio only. Do not include subdomains.
): ICoreEnvConfig => ({
    debug,
    storyBookUI,
    logger: logger, // (): ILogger => new TelemetryLogger(telemetryInstrumentationKey),

    // cookiesManager:
    //     () => Axeptio(hostname),

    intercom: loadIntercom,
    logMsalInfo // : false
});

export default configFactory;

