import {createBrowserRouter, Outlet} from "react-router-dom";
import BankinAppWithCustomerTokenWrapper from "./Pages/Main/BankinAppWithCustomerTokenWrapper";
import BankinCallbackWithCustomerTokenWrapper from "./Pages/Callback/BankinCallbackWithCustomerTokenWrapper";
import ErrorRouterHandler from "factor-lib/Errors/ErrorRouterHandler.tsx";
import navigateWrapper from "factor-lib/contexts/navigateWrapper.ts";
import type {To} from "@remix-run/router";
import type {NavigateOptions} from "react-router/dist/lib/context";
import FromCustomer from "./Pages/FromCustomer.tsx";
import BankinFromCustomerUrl from "factor-lib/customerBankin/bankinFromCustomerUrl.ts";
import customerTokenName from "./customerTokenName.ts";

const router =
    createBrowserRouter([
        {
            path: '/',
            element: <Outlet/>,
            errorElement: <ErrorRouterHandler />,
            children: [
                {
                    path: BankinFromCustomerUrl,
                    element: <FromCustomer />
                },
                {
                    path: '/callback',
                    element: <BankinCallbackWithCustomerTokenWrapper />
                },
                {
                    path: `/:${customerTokenName}`,
                    element: <BankinAppWithCustomerTokenWrapper/>
                }
            ]
        }
    ]);

navigateWrapper.value = (to: To, options?: NavigateOptions) => router.navigate(to, options);

export default router;