import Button, {KIND_OUTLINED, SIZE_FIXED} from "../Buttons/Button.tsx";
import {SynchronizeBankinId} from "./Consts.ts";
import IBankinItem from "./model/IBankinItem.ts";
import {ReactNode} from "react";

const Completed = (
    {
        connectedItemsWithTransactions,
        isRefreshing,
        connectNewItemIsLoading,
        synchronizeNewAccount,

        children
    }: {
        connectedItemsWithTransactions: IBankinItem[];
        isRefreshing: boolean;
        connectNewItemIsLoading: boolean;
        synchronizeNewAccount:() => void;

        children?: ReactNode; // Extra information
    }
) =>
    <div>
        <div>Les relevés bancaires suivants ont été synchronisés avec succès.</div>
        <ul style={{listStyle: "inside"}}
            className='p-margin-top-5 p-margin-bottom-5'>
            { connectedItemsWithTransactions.map((i, iIndex) =>
                <li key={`i-${iIndex}`}> {i.content.bankName} </li>)
            }
        </ul>

        { children }
        <div className='p-margin-top-4'>
            <Button id={SynchronizeBankinId}
                    kind={KIND_OUTLINED}
                    size={SIZE_FIXED}
                    text='Gérer les synchronisations'
                    actionO={(!isRefreshing && !connectNewItemIsLoading && (() => synchronizeNewAccount(
                        // bankinStatus,
                        // refresh,
                        // connectNewItem,
                    ))) || null /* (!completed && !synchronizing) ? click : null */}
                    isLoading={connectNewItemIsLoading /* clickIsLoading */}/>
        </div>
    </div>;

export default Completed;