import IBankinStateO from "./model/IBankinStateO.ts";

const click = (
    bankinState: IBankinStateO,
    refresh: () => void,
    connectNewItem: () => void,
): void => {
    if (!!bankinState) {
        // Reload the bankin items
        refresh();
        return;
    } // else
    return connectNewItem();
}

export const synchronizeNewAccount = (
    bankinState: IBankinStateO,
    refresh: () => void,
    connectNewItem: () => void,
): void => {
    click(
        bankinState,
        refresh,
        connectNewItem,
    );
}