import ICoreEnvConfig from "./ICoreEnvConfig";
import configFactory from "./configFactory";
import TelemetryLogger from "../services/logger/TelemetryLogger";

export const ProdTelemetryCustomerInstrumentationKey = '53a7fb5c-019f-4331-b370-2372d39e259e';

export const ProdTelemetryBuyerInstrumentationKey = '9e498e54-d0b4-4454-a36e-c8d2e2b71980';

export const ProdHostname = 'dimpl.io';

export const ProdAzureB2CDomain = 'dimpl';

export const ProdSigninOrUpFlowName = 'B2C_1_sign-up-or-in';

const prodConfigFactory = (
    telemetryInstrumentationKey: string
): ICoreEnvConfig =>
    configFactory(
        false,
        false,
        // TODO : async
        () => new TelemetryLogger(telemetryInstrumentationKey),
        false
        // 'dimpl.io' // hostname,
    );

export default prodConfigFactory;
