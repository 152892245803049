// import IBankinError, {LoginFailedError, NeedsManualRefreshError, ProAccountError} from "kether-shared-lib/bankin/IBankinError";
// import BankinStatusCode from "kether-shared-lib/bankin/BankinStatusCode";

import IBankinError, {
    LoginFailedError,
    NeedsManualRefreshError,
    ProAccountError
} from "./IBankinError";
import BankinStatusCode from "./BankinStatusCode";

const nonConnectedBankinItemStatusToMessage: Map<number, IBankinError> = new Map<number, IBankinError>();

export const NoTransactionsError: IBankinError = ({
    title: 'Transactions non détectées',
    message: 'Nous n’avons pas pu détecter de transactions bancaires de moins de 3 mois sur votre compte. Réessayez de synchroniser un autre compte de votre société.'
});
nonConnectedBankinItemStatusToMessage.set(BankinStatusCode.Ok, NoTransactionsError);
nonConnectedBankinItemStatusToMessage.set(BankinStatusCode.LoginFailed, LoginFailedError);

nonConnectedBankinItemStatusToMessage.set(
    BankinStatusCode.NeedsManualRefresh,
    NeedsManualRefreshError
);

nonConnectedBankinItemStatusToMessage.set(
    BankinStatusCode.ProAccountLocked,
    ProAccountError
);

export default nonConnectedBankinItemStatusToMessage;
