// TODO : move to env directory ?

export const isLocal = process.env.NODE_ENV === 'development' /* Is this defined somewhere ? */;

// Can be null when pre-rendering :'(
export const getEnv = <T> (profileNameProvider: () => (string | null), local: T, dev: T, demo: T, prod: T): T | null => {
    if (isLocal) {
        return local;
    } // else

    const profileName = profileNameProvider();

    if (!profileName) {
        return null;
    }

    switch (profileName) {
        case 'dev': return dev;
        case 'demo': return demo;
        case 'prod': return prod;
        default:
            return null;
    }
};

// Generic error meaningless to customers
export const envError = <T> (): T => {
    throw new Error('Environment error');
};

export const ensureNonNullEnvName = (envName?: string): string => {
    if (!envName) {
        envError();
    }
    return envName!;
}