import { IButtonProps } from "../Buttons/Button";
import WithMainButtonModal, { WithMainButtonModalContent } from "./WithMainButtonModal";

export const TextOnlyModalContent = (
    {
        message,
        buttonsProps
    }: {
        message: string;
        buttonsProps: IButtonProps;
    }
) =>
    <WithMainButtonModalContent buttonsProps={buttonsProps}>
        { message }
    </WithMainButtonModalContent>;

const TextOnlyModal = (
    {
        className,
        close,
        message,
        maxWidth,
        fullMaxWidth,
        buttonsProps
    }: {
        className?: string;
        close: (() => void) | null;
        message: string;
        maxWidth: string | null;
        fullMaxWidth: boolean;
        buttonsProps: IButtonProps;
    }
) =>
    <WithMainButtonModal className={className}
                   close={close}
                   maxWidth={maxWidth}
                   buttonsProps={buttonsProps}
                   fullMaxWidth={fullMaxWidth}>
        { message }
    </WithMainButtonModal>;

export default TextOnlyModal;
