
export const numberFormat2Digits = (i: number) => `${i < 10 ? '0' : ''}${i.toString(10)}`;

// Rename to DateTime ?
export const serverDateSerialization = (date: Date): string => date.toISOString();

// Rename to DateTime ?
export const serverDateDeserialization = (dateStr: string): Date => new Date(dateStr); // TODO  : check

// https://stackoverflow.com/questions/563406/add-days-to-javascript-date
export const addDays = (originDate: Date, days: number): Date => {
    const result = new Date(originDate);
    result.setDate(result.getDate() + days);
    return result;
}

export const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

const dayDate = (dateTime: Date): number =>
    // Pk on a besoin de passer par utc ?
    Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());

// https://stackoverflow.com/questions/3224834/get-difference-between-2-dates-in-javascript
export const dateDiffDays = (originDate: Date, targetDate: Date): number =>
    Math.round(
        (dayDate(targetDate) - dayDate(originDate)) / oneDay
    );

export const dateFromDMY = (day: number, month : number /* 1-indexed */, year: number) =>
    // On doit passer en UTC pour la serialization par axios
    new Date(Date.UTC(year, month - 1, day));
        // new Date(year, month - 1, day);

export const serverDateLocalTimeSerialization = (date: Date): string => toISOLocal(date);

// https://stackoverflow.com/questions/49330139/date-toisostring-but-local-time-instead-of-utc
// toIsoString will output something like 2022-11-03T10:26:00.000+01:00,
// We want 2022-11-03T11:26:00.000 for our server to parse the time properly

const toISOLocal = (dateTime: Date): string => {
    const z  = (n: number) =>  ('0' + n).slice(-2);
    const zz = (n: number) => ('00' + n).slice(-3);
    return dateTime.getFullYear() + '-'
        + z(dateTime.getMonth()+1) + '-' +
        z(dateTime.getDate()) + 'T' +
        z(dateTime.getHours()) + ':'  +
        z(dateTime.getMinutes()) + ':' +
        z(dateTime.getSeconds()) + '.' +
        zz(dateTime.getMilliseconds());
}

export const comparingDate = <T>(extractor: (e: T) => Date) =>
    (i1: T, i2: T): number => {
        const d1: Date = extractor(i1);
        const d2: Date = extractor(i2);
        return comparingTwoDates(d1,d2);
    };

// ==/=== between Date is a reference equality, Date only supports <(=) and >(=) comparisons
// leave equal case at the end or use getTime()
// https://www.freecodecamp.org/news/javascript-date-comparison-how-to-compare-dates-in-js/
export const comparingTwoDates = (d1: Date, d2: Date): number =>
    d1 < d2 ? -1 : d1 > d2 ? 1 : 0;

const DIMPL_DEFAULT_DATE_FORMAT: Intl.DateTimeFormatOptions =
    ({ year: 'numeric', month: '2-digit', day: '2-digit' });

export const userFormatDateExtended = (date: Date, options: Intl.DateTimeFormatOptions): string =>
    date.toLocaleDateString('fr-FR', options);

export const userFormatDate = (date: Date): string =>
    userFormatDateExtended(date, DIMPL_DEFAULT_DATE_FORMAT);

const DIMPL_DEFAULT_DATE_TIME_FORMAT: Intl.DateTimeFormatOptions =
    ({ year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit', second: '2-digit' });

export const userFormatDateTime = (date: Date): string =>
    userFormatDateExtended(date, DIMPL_DEFAULT_DATE_TIME_FORMAT);