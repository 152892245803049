import {KeyboardEvent} from 'react';

import PSpinner from "../Spinner/PSpinner";

import './button.scss';

export type ButtonKind = 'is-primary' | 'is-secondary' | 'is-outlined' | 'is-danger';

export const KIND_PRIMARY: ButtonKind = 'is-primary';
export const KIND_SECONDARY: ButtonKind = 'is-secondary';
export const KIND_OUTLINED: ButtonKind = 'is-outlined';
export const KIND_DANGER: ButtonKind = 'is-danger';

export type ButtonSize = 'is-fixed' | 'is-fluid' | 'is-compact';
export const SIZE_FIXED: ButtonSize = 'is-fixed';
export const SIZE_FLUID: ButtonSize = 'is-fluid'; // weird
export const SIZE_COMPACT: ButtonSize = 'is-compact';

export interface IBaseButtonProps {
    id?: string;
    kind?: ButtonKind; // default : KIND_PRIMARY
    size?: ButtonSize; // default: SIZE_FIXED
    className?: string;
}

export interface IButtonProps extends IBaseButtonProps {
    actionO: (() => void) | null;
    isLoading: boolean;
    text: string;
}

const Button = (
    {
        id,
        kind,
        size,
        className,
        // Un peu bizarre de les mettre cote à côte,
        // on voudrait peut etre une hierarchie,
        // mais on souvent des cas où on veut préciser l'un sans l'autre
        actionO,
        isLoading,
        text
    }: IButtonProps
) => {

    const finalKind = kind ?? KIND_PRIMARY;
    const finalSize = size ?? SIZE_FIXED;

    const clickableNotLoading: ((/* e: UIEvent */) => void) | null =
        (!!actionO && !isLoading) ? actionO : null

    return (
        <div id={id}
             className={`p-button ${finalKind} ${finalSize}${
                 !actionO ? ' is-disabled' :  ''
             }${!!clickableNotLoading ? ' p-clickable' : ''}${!!className ? ` ${className}` : ''}`}
             onClick={ /* clickHandlerInt */() => {
                 if (!!clickableNotLoading) {
                     return clickableNotLoading();
                 }
             }}
            onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                if (e.key === 'Enter') {
                    if (!!clickableNotLoading) {
                        return clickableNotLoading();
                    }
                    // clickHandlerInt(e);
                }
            }}
        >
            { isLoading
                ? (
                    size === SIZE_FLUID
                        ? /* For fluid we need to keep the same space */
                            <div style={{position: 'relative'}}>
                                <div style={{visibility: 'hidden'}}> {text} </div>
                                <div style={{position: 'absolute', zIndex: 1, top: 0, left:0, right:0, bottom: 0 }} className='p-both-center'>
                                    <PSpinner/>
                                </div>
                            </div>
                        : <PSpinner/>
                )
                : <div> { text } </div>
            }
        </div>
    );
};

export default Button;
