
import { ReactComponent as Synchronize } from "./Synchronize.svg";
import { ReactComponent as Mobile } from "./Mobile.svg";
import { ReactComponent as Document } from "./Document.svg";

import './presentationcard.scss';

const SyncMessage = 'Munissez-vous des identifiants de votre banque en ligne. Ils permettent d’accéder en toute sécurité à votre compte.';

const DocumentMessage = 'Cette connexion est sécurisée. Dimpl ne stocke pas vos identifiants et n’a en aucun cas la possibilité d’effectuer des opérations sur votre compte.';

const MobileMessage = 'Un SMS ou code de validation pourra vous être envoyé. Gardez à proximité votre téléphone ou boitier permettant d’accéder à votre compte.';

export const BankinCardPresentationId = 'bankinCardPresentation';

const CardPresentation = (
    {
        className
    }: {
        className: string | null
    }
) =>
    <div id={BankinCardPresentationId}
         className={`${!!className ? `${className} ` : ''}p-b-small p-warn-border`}>
        <div className='p-padding-5 p-warn-header p-horizontal-center'>
            <div className='p-icon p-warn-border p-warn-icon'>!</div>
            <div className='p-margin-left-6 p-bold' style={{lineHeight: '24px'}}>Avant de commencer</div>
        </div>
        <div className='p-bpc-separator'/>
        <div className='columns'>
            <div className='column'>
                <div className='p-padding-4'>
                    <div className='p-warn-icon p-margin-bottom-4'>
                        <Synchronize />
                    </div>
                    { SyncMessage }
                </div>
            </div>
            <div className='column'>
                <div className='p-padding-4'>
                    <div className='p-warn-icon p-margin-bottom-4'>
                        <Document />
                    </div>
                    { DocumentMessage }
                </div>
            </div>
            <div className='column'>
                <div className='p-padding-4'>
                    <div className='p-warn-icon p-margin-bottom-4'>
                        <Mobile />
                    </div>
                    { MobileMessage }
                </div>
            </div>
        </div>
    </div>;

export default CardPresentation;
