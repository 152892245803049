
import './link.scss';

export interface ILinkAction {
    // One or the other
    clickHandler?: () => void; // TODO : rename to click ?
    href?: string; // Better than goTo for SEO
}

export type CSSClass = string;

export type CSSClassMap = Record<string, CSSClass>;

// TODO : enum ?
export const KIND_PRIMARY = 'primary';
export const KIND_DANGER = 'danger';
export const KIND_BLUE_BACKGROUND = 'blueBackground';
export const KIND_WHITE_BLUE_BACKGROUND = 'whiteBackground';

const kindClasses: CSSClassMap = { };
kindClasses[KIND_PRIMARY] = 'is-primary';
kindClasses[KIND_DANGER] = 'is-danger';
kindClasses[KIND_BLUE_BACKGROUND] = 'p-p-blue-background';
kindClasses[KIND_WHITE_BLUE_BACKGROUND] = 'p-p-white-blue-background';

export const kinds = Object.keys(kindClasses); // TODO : comment

const Link = /* <N, O>  */(
    {
        className,
        id,
        kind,
        text,
        action
    }: {
        id?: string;
        className?: string;
        kind?: string;
        text: string;
        action: ILinkAction;
    }
) => {
    const classes = kindClasses[kind ?? KIND_PRIMARY];
    // console.error(`action:${action}`);
    // console.log(`action.href : ${action.href}, action.clickHandler : ${action.clickHandler}`)
    return (
        <a id={id}
           href={action.href /* null or not */}
           className={`${!!className ? `${className} ` : ''}p-link p-bold ${classes}`}
           onClick={action.clickHandler /* ?? (false as unknown as undefined) */}
        >
            { text }
        </a>
    );
}
    // <PLink className={`${!!className ? `${className} ` : '' }`}
    //        id={id}
    //        kind={kind}
    //        text={text}
    //        action={action}/>;

export default Link;
