import devConfigFactory, {DevTelemetryCustomerInstrumentationKey} from "factor-lib/envs/devConfigFactory";
import IBankinEnvConfig from "./IBankinEnvConfig";

const DevEnv: IBankinEnvConfig = ({
    ...devConfigFactory(
        DevTelemetryCustomerInstrumentationKey,
    ),
    backendUrl: 'https://factor-dev-bankin-function.azurewebsites.net',
});

export default DevEnv;
