
// Same as eSeverityLevel of application-insights , but a lot of issues with typescript, and I cannot find an example
const enum ILoggerLevel {
    Verbose = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4
}

export default ILoggerLevel;